import { FC, useState, ChangeEvent, useEffect } from "react"
import styled from "styled-components"
import { ContentfulProductWithShopifyData } from "@lib/types"
import { getOptionValue } from "@lib/utils"
import { useCheckout, useShopifyProduct, useTracking, useCustomer } from "@hooks/index"
import {
  ProductVariant,
  ProductVariantEdge,
} from "shopify-storefront-api-typings"

import Image from "next/image"
import { Price } from "@components/common"
import { Dropdown, UpsellButton } from "@components/ui"
import { decode } from "shopify-gid"

interface Props {
  product: ContentfulProductWithShopifyData
}

export const UpsellThumbnail: FC<Props> = ({ product }) => {
  const { slug, category, title } = product
  const { shopifyProduct } = useShopifyProduct(product)

  const edges: ProductVariantEdge[] = shopifyProduct?.variants?.edges
  const { id, addLineItem } = useCheckout()
  const { currency } = useCustomer()
  const { track, tikTokTrack } = useTracking()
  const [quantity, setQuantity] = useState(1)

  let quantityOptions = [...Array(51).keys()].map(val => ({
    name: val,
    value: val,
  }))
  quantityOptions.shift()

  const [selectedVariant, setSelectedVariant] = useState<ProductVariant | null>(
    null
  )

  const [selectedOptionValues, setSelectedOptionValues] = useState<string[]>([])
  const [variantOptions, setVariantOptions] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (shopifyProduct) {
      setSelectedVariant(shopifyProduct.variants.edges[0].node)
      setVariantOptions(
        shopifyProduct.variants.edges.map(({ node }: { node: any }) => ({
          name: getOptionValue(node.selectedOptions),
          value: node.id,
        }))
      )
    }
  }, [shopifyProduct])

  useEffect(() => {
    if (selectedVariant) {
      setSelectedOptionValues(
        selectedVariant?.selectedOptions?.map(({ value }) => value) || []
      )
    }
  }, [selectedVariant])

  const handleSelectVariant = (e: ChangeEvent<HTMLSelectElement>) => {
    setSelectedVariant(
      edges.filter(({ node }) => e.target.value === node.id)[0]?.node
    )
  }

  const addToCart = async () => {
    const variantId = selectedVariant?.id
    setLoading(true)
    await addLineItem({ quantity, variantId })
    setLoading(false)

    track("Product Added", {
      cart_id: decode(id).id,
      product_id: decode(variantId).id,
      name: title,
      category: category?.name,
      variant: selectedVariant?.title,
      sku: selectedVariant?.sku,
      price: parseFloat(selectedVariant?.priceV2?.amount),
      brand: "Haute Hijab",
      url: `https://www.hautehijab.com/${slug}`,
      quantity,
      image_url: selectedVariant?.image?.transformedSrc,
      type: "Upsell",
    })
    
    const currencyAdjustedPrice = selectedVariant?.presentmentPrices.edges.find((edge: {node: {price: {currencyCode: string}}}) => edge.node.price.currencyCode === currency)?.node.price.amount || null;
    tikTokTrack("AddToCart", {
      content_type: "product",
      content_id: decode(selectedVariant?.id).id,
      content_name: selectedVariant?.title,
      currency: currency,
      price: currencyAdjustedPrice,
      value: parseFloat(currencyAdjustedPrice) * (quantity),
      quantity: quantity
    })
  }

  return (
    <Thumbnail>
      <ImageWrapper>
        {selectedVariant && (
          <Image src={selectedVariant?.image?.transformedSrc} layout="fill" />
        )}
      </ImageWrapper>
      <Content>
        <ContentHeader>
          <Info>
            <Title>{title}</Title>
            <SelectedOptions>
              {selectedOptionValues?.map((value: string, i: number) => {
                return (
                  <span key={value}>
                    {i !== 0 ? " | " : ""}
                    {value}
                  </span>
                )
              })}
            </SelectedOptions>
            <PriceWrapper>
              {selectedVariant && <Price variant={selectedVariant} />}
            </PriceWrapper>
          </Info>
          <UpsellButton addToCart={addToCart} loading={loading}/>
        </ContentHeader>
        <DropdownContainer>
          <QuantityDropdown
            name="quantity"
            value={quantity}
            onChange={(e: ChangeEvent<HTMLSelectElement>) =>
              setQuantity(+e.target.value)
            }
            options={quantityOptions}
          />
          {selectedVariant && variantOptions.length > 1 && (
            <Dropdown
              name="size"
              value={selectedVariant.id}
              onChange={handleSelectVariant}
              options={variantOptions}
            />
          )}
        </DropdownContainer>
      </Content>
    </Thumbnail>
  )
}

const Thumbnail = styled.li`
  display: flex;
  margin: 0 2rem 2rem;

  &:last-child {
    margin: 0 2rem;
  }
`

const ImageWrapper = styled.div`
  position: relative;
  width: 100%;
  max-width: 7.8rem;
  height: 12rem;
  background: ${({ theme }) => theme.color.greyLight};

  img {
    object-fit: cover;
  }
`

const Content = styled.div`
  flex: 1;
  position: relative;
  margin-left: 1.4rem;
`

const ContentHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`

const Info = styled.div`
  margin-right: 2rem;
`

const Title = styled.div`
  margin: 0 0 1rem;
  font-family: ${({ theme }) => theme.font.heading};
  font-size: 1.8rem;
  font-weight: 300;
`

const SelectedOptions = styled.div`
  margin: 0 0 1rem;
  color: ${({ theme }) => theme.color.grey};
  font-size: 1.2rem;
  line-height: 1.8rem;
`

const PriceWrapper = styled.div`
  margin: 0 0 1rem;
  font-family: ${({ theme }) => theme.font.primary};
  font-size: 1.4rem;
`

const DropdownContainer = styled.div`
  display: flex;
`

const QuantityDropdown = styled(Dropdown)`
  flex-shrink: 0;
  margin-right: 0.9rem;

  @media (min-width: ${({ theme }) => theme.breakpoint.md}px) {
    margin-right: 1.4rem;
  }
`
