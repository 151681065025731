import { FC, createContext, useEffect, useState, useReducer } from 'react';
import type { Customer, Address, WishlistItem } from '@lib/types';
import { decode } from 'shopify-gid';
import { mapLocaleToCurrencyCode } from '@lib/config';
import {
  loginCustomer,
  customerUpdate,
  customerAddressCreate,
  customerAddressUpdate,
  customerAddressDelete,
  getCustomer,
  CustomerWithAccessToken,
  LoginCustomerResponse
} from '@lib/account';

import { 
  ProductVariant, 
  ProductVariantPricePair, 
  CustomerAccessToken, 
  CustomerCreatePayload, 
  CustomerUpdatePayload, 
  CustomerAddressDeletePayload, 
  CustomerAddressUpdatePayload, 
  CustomerAddressCreatePayload } from "shopify-storefront-api-typings"

import { useTracking } from '@hooks/index';
import * as Sentry from "@sentry/react";
import { saveState, deleteState, LocalStorageKeys, loadState } from '@lib/utils';

const saveCustomer = saveState(LocalStorageKeys.CUSTOMER);
const saveWishlist = saveState(LocalStorageKeys.WISHLIST);
const saveCurrency = saveState(LocalStorageKeys.CURRENCY);

interface State {
  customer: Customer;
  currency: string;
  wishlist: WishlistItem[],
}

const blankCustomer = {
  addresses: {},
  defaultAddress: {},
  email: '',
  firstName: '',
  lastName: '',
  orders: {},
  phone: '',
  metafields: [],
  customerAccessToken: {
    accessToken: '',
    expiresAt: '',
  },
  updatedAt: '',
};

const initialState = {
  customer: (loadState(LocalStorageKeys.CUSTOMER) || blankCustomer) as Customer,
  currency: loadState(LocalStorageKeys.CURRENCY),
  wishlist: loadState(LocalStorageKeys.WISHLIST) || [],
};

type Action =
  | {
      type: 'CHECK_CUSTOMER_EXPIRY';
    }
  | {
      type: 'LOGOUT_CUSTOMER';
    }
  | {
      type: 'UPDATE_CUSTOMER';
      payload: CustomerWithAccessToken
    }
  | {
      type: 'ADD_TO_WISHLIST';
      payload: WishlistItem;
    }
  | {
      type: 'DELETE_FROM_WISHLIST';
      payload: string;
    }
  | {
      type: 'SET_CURRENCY';
      payload: string;
    };

type ContextState = State & { [key: string]: any }

export const CustomerContext = createContext<ContextState>(initialState);

const reducer = (state: State, action: Action) => {
  switch (action.type) {
    case 'CHECK_CUSTOMER_EXPIRY': {
      const customer = loadState(LocalStorageKeys.CUSTOMER);

      const currentTime = Date.now();
      const expireTime = new Date(customer?.customerAccessToken?.expiresAt).getTime();

      if (expireTime <= currentTime) {
        deleteState(LocalStorageKeys.CUSTOMER);
        return {
          ...state,
          customer: null,
        };
      }
      return {
        ...state,
        customer,
      };
    }
    case 'UPDATE_CUSTOMER': {
      saveCustomer(action.payload);
      return {
        ...state,
        customer: action.payload,
      };
    }
    case 'LOGOUT_CUSTOMER': {
      deleteState(LocalStorageKeys.CUSTOMER);

      return {
        ...state,
        customer: null,
      };
    }
    case 'ADD_TO_WISHLIST': {
      const wishlist = [...state.wishlist, action.payload];
      saveWishlist(wishlist);

      return {
        ...state,
        wishlist,
      };
    }
    case 'DELETE_FROM_WISHLIST': {
      const wishlist = state.wishlist.filter(({ id }) => id !== action.payload);

      saveWishlist(wishlist);
      return {
        ...state,
        wishlist,
      };
    }
    case 'SET_CURRENCY': {
      saveCurrency(action.payload);

      return {
        ...state,
        currency: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

interface Props {
  locale: string;
}



export const CustomerProvider: FC<Props> = ({ children, locale }) => {
  const [state, dispatch] = useReducer(reducer, {
    ...initialState,
    currency: loadState(LocalStorageKeys.CURRENCY) || mapLocaleToCurrencyCode[locale],
  });

  const [loading, setLoading] = useState(false);
  const { identify } = useTracking();

  useEffect(() => {
    dispatch({ type: 'CHECK_CUSTOMER_EXPIRY' });
  }, []);

  type CustomerResponse = 
  LoginCustomerResponse 
  | CustomerCreatePayload 
  | CustomerUpdatePayload
  | CustomerAddressDeletePayload
  | CustomerAddressUpdatePayload
  | CustomerAddressCreatePayload 

  const dispatchCustomer = async (callbackPromise: Promise<CustomerResponse>) => {
    setLoading(true);
    const response = await callbackPromise
    setLoading(false);
  
    let customer = state.customer
    if ('customer' in response) {
      customer = {
        ...response.customer,
        customerAccessToken: ('customerAccessToken' in response) ? response.customerAccessToken : state?.customer?.customerAccessToken,
      }
    } else if (state?.customer?.customerAccessToken) {
      customer = await getCustomer(state.customer.customerAccessToken);
      customer = {...state.customer, ...customer}
    }
    const traits = { ...customer, orders: undefined, customerAccessToken: undefined }
    identify(decode(customer.id).id, traits);
    dispatch({ type: 'UPDATE_CUSTOMER', payload: customer });
    return response
  }

  // Fetch and return fresh customer data and UPDATE_CUSTOMER (save state)
  const refetchCustomer = async (customerAccessToken: CustomerAccessToken) => {
    const customer = await getCustomer(customerAccessToken);
    dispatch({ type: 'UPDATE_CUSTOMER', payload: customer });
    return customer;
  };

  const login = (loginInput: { email: string; password: string }) => {
    Sentry.setUser({
     email: loginInput.email
    })

    return dispatchCustomer(
      loginCustomer({
        ...loginInput,
      })
    );
  }

  const logoutCustomer = () => {
    dispatch({ type: 'LOGOUT_CUSTOMER' });
  };

  const updateCustomer = (customer: Customer) =>
    dispatchCustomer(
      customerUpdate({
        customerAccessToken: state.customer.customerAccessToken.accessToken,
        customer,
      })
    );

  const addAddress = (address: Address) =>
    dispatchCustomer(
      customerAddressCreate({
        customerAccessToken: state.customer.customerAccessToken.accessToken,
        address,
      })
    );

  const updateAddress = (payload: { id: string; address: Address }) =>
    dispatchCustomer(
      customerAddressUpdate({
        customerAccessToken: state.customer.customerAccessToken.accessToken,
        ...payload,
      })
    );

  const deleteAddress = (payload: { id: string }) =>
    dispatchCustomer(
      customerAddressDelete({
        customerAccessToken: state.customer.customerAccessToken.accessToken,
        ...payload,
      })
    );

  const addToWishlist = (wishlistItem: WishlistItem) =>
    dispatch({ type: 'ADD_TO_WISHLIST', payload: wishlistItem });

  const deleteFromWishlist = (storefrontId: string) =>
    dispatch({ type: 'DELETE_FROM_WISHLIST', payload: storefrontId });

  const setCurrency = (currencyCode: string) =>
    dispatch({ type: 'SET_CURRENCY', payload: currencyCode });

  const getPricePair = function (variant: ProductVariant) {
    if (!variant) {
      return
    }
    const reduced = variant?.presentmentPrices?.edges.reduce(
      (collect: any, { node }: { node: ProductVariantPricePair }, _index: any) => {
        collect[node.price.currencyCode] = node;
        return collect;
      },
      {} as any
    );
    return reduced[state.currency] || reduced.USD;
  };

  const getCurrencyPrice = (variant: ProductVariant) => {
    const price = getPricePair(variant)?.price;
    const amount = price ? +price.amount : 0;
    return amount;
  };

  const formatCurrencyPrice = (price: number) =>
    new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: state.currency,
      minimumFractionDigits: 0,
    }).format(price);

  const value = {
    ...state,
    loading,
    loginCustomer: login,
    logoutCustomer,
    updateCustomer,
    addAddress,
    updateAddress,
    deleteAddress,
    addToWishlist,
    deleteFromWishlist,
    setCurrency,
    getPricePair,
    getCurrencyPrice,
    formatCurrencyPrice,
    refetchCustomer,
  };

  return <CustomerContext.Provider value={value}>{children}</CustomerContext.Provider>;
};
