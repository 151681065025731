import type { Metafield, MetafieldEdge, Product } from "shopify-storefront-api-typings"

const headers = {
  "Access-Control-Allow-Origin": "*",
  "Access-Control-Allow-Headers": "Content-Type",
  "Content-Type": "application/json",
}

export const headersAdmin = {
  "Content-Type": "application/json",
  "X-Shopify-Access-Token": process.env.SHOPIFY_ADMIN_ACCESS_TOKEN,
}

export const shopifyConfig = {
  "Content-Type": "application/json",
  Accept: "application/json",
  "X-Shopify-Storefront-Access-Token":
    process.env.NEXT_PUBLIC_SHOPIFY_STOREFRONT_ACCESS_TOKEN,
}

export const statusReturn = (code: number, body: {}) => {
  return {
    statusCode: code,
    headers,
    body: JSON.stringify(body),
  }
}

export const preparePayload = (query: string, v: {}) => {
  return {
    query,
    variables: v,
  }
}

export const regionToFulfillmentId = function (region: string) {
  switch (region) {
    case "en-GB":
      return "60894117984"
    default:
      return "49481351"
  }
}

export const currencyToFulfillmentId = function (currency: string) {
  switch (currency) {
    case "GBP":
      return "60894117984"
    default:
      return "49481351"
  }
}

export function updateInventoryByLocale(
  product: Product,
  country: string = "en-US"
) {

  const location = regionToFulfillmentId(country)
  product.variants.edges = product.variants.edges.map(variant => {
    const found: Metafield | undefined = (variant.node.metafields.edges.find((metafield: MetafieldEdge) => metafield.node.key === `location:${location}`) || {}).node
    
    if (!found || !found.value) {
      console.error("no inventory set, using default %S", variant.node.id)
      return variant
    }

    const inv = Number(found.value)
    variant.node = {
      ...variant.node,
      availableForSale: inv > 0,
      quantityAvailable: inv,
    }
    return variant
  })

  return product
}
