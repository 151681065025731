import { useEffect, useState } from "react"
import styled from "styled-components"
import { SectionWithTitle } from "./"
import Arrow from "@svg/arrow.svg"
import Star from "@svg/star.svg"
// import { format } from 'date-fns'

export interface StampedReview {
  id: number
  author: string
  reviewTitle: string
  reviewMessage: string
  reviewRating: number
  reviewDate: string
  productName: string
}

interface StampedResult {
  data: StampedReview[]
}

interface Props {
  reviews: StampedReview[]
  total_reviews: number
}

const BASE_URL = "https://stamped.io/api/widget/reviews?"
const API_KEY = "pubkey-x6x8XHkF25qzkFe082aGK2R76fr6kb"
const STORE_URL = "www.hautehijab.com"

export const get_product_reviews = function (
  product_id: string
): Promise<StampedResult> {
  const query = Object.entries({
    storeUrl: STORE_URL,
    apiKey: API_KEY,
    productId: product_id,
    take: 25,
    page: 1,
  })
    .map(([k, v]) => encodeURIComponent(k) + "=" + encodeURIComponent(v))
    .join("&")
  const url = BASE_URL + query
  return fetch(url).then(res => res.json())
}

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const ReviewList = styled.div`
  display: flex;
  max-width: 74rem;
  flex-direction: column;
`

const ReviewItem = styled.div`
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  border-top: 1px solid #25211e;
  display: flex;
  flex-direction: column;
`

const Pagination = styled.div`
  display: flex;
  max-width: 30rem;
`

const PageItem = styled.div<any>`
  display: flex;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  min-width: 5rem;
  min-height: 5rem;

  :hover {
    background: black;
    color: white;
  }

  ${props =>
    props.selected
      ? `
            background: black;
            color: white;
        `
      : ``}
`

const ArrowLeft = styled.button`
  display: none;
  cursor: pointer;
  min-width: 5rem;
  min-height: 5rem;
  color: black;
  border: 1px solid black;
  margin-right: 1rem;
  margin-left: 1rem;
  transition: background-color 0.2s linear;
  path {
    stroke: black;
  }

  :hover {
    background: black;
    path {
      stroke: white;
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoint.md}px) {
    display: block;
  }
`

const ArrowRight = styled(ArrowLeft)`
  svg {
    transform: rotate(180deg);
  }
`
const PageinateWrap = styled.div`
  display: flex;
  justify-content: center;
`

const ReviewMessage = styled.div`
  font-size: 1.5rem;
  line-height: 170%;
  font-family: Gotham;
`
const ReviewAuthor = styled.div`
  padding-top: 1rem;
  font-size: 1.8rem;
  line-height: 130%;
  text-transform: capitalize;
`
const ReviewDate = styled.div`
  font-size: 1rem;
  line-height: 2.4rem;
  opacity: 0.7;
  text-transform: uppercase;
`
const RatingStar = styled(Star)<any>`
  ${props =>
    props.$filled === true
      ? ""
      : `
        path {
            fill: #FFF;
            stroke: #000;
        }
    `}
`

const Rating = ({ rating }: any) => {
  return (
    <div style={{ paddingTop: "1rem", paddingBottom: "1rem" }}>
      {new Array(5).fill(0).map((_, idx: number) => {
        return <RatingStar key={idx} $filled={idx + 1 <= rating} />
      })}
    </div>
  )
}

const Paginate = ({ onChange, current, pages }: any) => {
  const inc = current + 1
  const dec = current - 1
  return (
    <Pagination>
      <ArrowLeft onClick={() => current > 0 && onChange(dec)}>
        <Arrow />
      </ArrowLeft>
      {new Array(pages).fill(0).map((_, idx) => {
        return (
          <PageItem
            key={idx}
            selected={current === idx}
            onClick={() => onChange(idx)}
          >
            {idx + 1}
          </PageItem>
        )
      })}
      <ArrowRight onClick={() => current < pages.length && onChange(inc)}>
        <Arrow />
      </ArrowRight>
    </Pagination>
  )
}

const ShortReviewWrap = styled.div`
  display: flex;
  align-items: center;
`

export const ShortReview = function ({ total_reviews }: any) {
  return (
    <ShortReviewWrap>
      <Rating rating={5} />
      {total_reviews > 0 ?
      <div style={{ padding: "1rem" }}><u><a href="#reviews-section">{total_reviews} {total_reviews == 1 ? "Review" : "Reviews"}</a></u></div>
      :
      <div style={{ padding: "1rem" }}>{total_reviews} Reviews</div> 
      }
    </ShortReviewWrap>
  )
}

export const ProductReviews = function ({ reviews, total_reviews }: Props) {
  const [page, set_page] = useState<number>(0)
  const start = page * 5
  const end = start + 5
  const total_pages = Math.round(reviews.length / 5)
  return (
    <SectionWithTitle
      preTitle={"Reviews"}
      id="reviews-section"
      title={"5 out of 5 stars"}
      backgroundColor={{ name: "beige", value: "#F3EDEA" }}
      containerMaxWidth="144rem"
    >
      <Wrap>
        {/* <ShortReview total_reviews={total_reviews} /> */}
        <ReviewList>
          {reviews.slice(start, end).map(review => {
            return (
              <ReviewItem key={review.id}>
                <Rating rating={review.reviewRating} />
                <ReviewMessage>{review.reviewMessage}</ReviewMessage>
                <ReviewAuthor>{review.author}</ReviewAuthor>
                <ReviewDate>
                  {/* {format(new Date(review.reviewDate), 'MMMM dd yyyy')} */}
                </ReviewDate>
              </ReviewItem>
            )
          })}
        </ReviewList>
        {total_pages > 1 && (
          <PageinateWrap>
            <Paginate
              onChange={(page: number) => set_page(page)}
              current={page}
              pages={total_pages}
            />
          </PageinateWrap>
        )}
      </Wrap>
    </SectionWithTitle>
  )
}
