import { FC } from "react"
import type { ProductVariant } from "shopify-storefront-api-typings"

import { useCustomer } from "@hooks/index"

interface Props {
  variant: ProductVariant
  isRange?: boolean
}
export const Price: FC<Props> = ({ variant, isRange }) => {
  const { getPricePair, formatCurrencyPrice } = useCustomer()

  const pricePair = getPricePair(variant)

  const price = pricePair.price
  const compareAtPrice = pricePair.compareAtPrice
  return (
    <>
      {compareAtPrice ? (
        <>
          <span style={{ fontWeight: 500 }}>{isRange ? `${formatCurrencyPrice(+price.amount)}+ ` : `${formatCurrencyPrice(+price.amount)} `}</span>
          <s>{isRange ? `${formatCurrencyPrice(+compareAtPrice.amount)}+` : `${formatCurrencyPrice(+compareAtPrice.amount)}`}</s>
        </>
      ) : (
        <span>{isRange ? `${formatCurrencyPrice(+price.amount)}+` : formatCurrencyPrice(+price.amount)}</span>
      )}
    </>
  )
}
