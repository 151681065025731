import type { GetStaticPropsContext } from 'next';
import { fetchContent } from './config';
import { getProductByHandle } from './shopify';
import {
  PRODUCT_QUERY,
  PAGE_SLUGS_QUERY,
  CONTENT_SECTION_QUERY,
  PRODUCT_PAGE_SECTION_QUERY,
} from './fragments';
import { ContentfulProduct } from "@lib/types"

interface PageContext extends GetStaticPropsContext {
  slug: string | string[] | undefined;
}

export const from_gql_id = (gql_id: string) => atob(gql_id).split('/').reverse()[0];

export const getProduct = async ({ slug, preview, locale }: PageContext) => {
  const res = await fetchContent(
    `
  {
    productCollection(limit: 1, where: {slug: "${slug}"}, preview: ${preview}, locale: "${locale}") {
      items {
        __typename
        sys {
          id
          publishedAt
        }
        hideFromSite
        hideFromCollections
        title
        slug
        productBadge
        description {
          json
          links {
            entries {
              hyperlink {
                __typename
                sys {
                  id
                }
                ${PAGE_SLUGS_QUERY}
              }
            }
          }
        }
        seoTitle
        seoDescription
        seoImage {
         url
         description
        }
        category {
          name 
          slug
          category {
            name
            slug
          }
        }
        productFamily {
          productsCollection(limit: 100) {
            items {
              title
              slug
              color {
                name
                value
                image {
                  url
                  description
                }
              }
            }
          }
        }
        color {
          name
          value
          image {
            url
            description
          }
        }
        sizesCollection(limit: 10) {
          items {
            name
          }
        }
        sizeChart {
          title
          subtitle
          table
          image {
            url (transform: {format: JPG_PROGRESSIVE})    
            description
            width
            height
          }
          richText {
            json
          }
        }
        fabricThickness {
          name
        }
        fabricTexture {
          name
        }
        video {
          sys {
            id
          }
          url
          contentType
        }
        uspCollection {
          items {
            image {
              url
              description
            }
            text
          }
        }
        texture
        thickness
        materialsCare
        wistiaId
        completeTheLookCollection(limit: 4) {
          items {
            ${PRODUCT_QUERY}
          }
        }
        contentSectionsCollection {
          items {
            ${CONTENT_SECTION_QUERY}
            ${PRODUCT_PAGE_SECTION_QUERY}
          }
        }
     }
    }
  }
  `,
    preview
  );
 
  if (res.productCollection.items.length === 0) return null

  const resCompleteTheLook = await fetchContent(
    `
    {
      productCollection(limit: 1, where: {slug: "${slug}"}, preview: ${preview}, locale: "${locale}") {
        items {
          productFamily {
            completeTheLook1Collection {
              items {
                ${PRODUCT_QUERY}
              }
            }
            completeTheLook2Collection {
              items {
                ${PRODUCT_QUERY}
              }
            }
            completeTheLook3Collection {
              items {
                ${PRODUCT_QUERY}
              }
            }
            completeTheLook4Collection {
              items {
                ${PRODUCT_QUERY}
              }
            }
          }
        }
      }
    }
    `,
    preview
  );

  if (res?.productCollection?.items) {
    const page = res.productCollection.items[0];
    const completeTheLookQuery = resCompleteTheLook?.productCollection.items[0].productFamily;

    // Filter out null items
    if (page.productFamily) {
      const productFamilyItems = page.productFamily.productsCollection.items.filter(
        (item: any) => item
      );
      page.productFamily.productsCollection.items = productFamilyItems;
    }

    const getRandomCompleteTheLookItems = () => {
      const items = [];
      const look1items = completeTheLookQuery?.completeTheLook1Collection.items;
      const look2items = completeTheLookQuery?.completeTheLook2Collection.items;
      const look3items = completeTheLookQuery?.completeTheLook3Collection.items;
      const look4items = completeTheLookQuery?.completeTheLook4Collection.items;

      look1items?.length > 0 &&
        items.push(look1items[Math.floor(Math.random() * look1items.length)]);
      look2items?.length > 0 &&
        items.push(look2items[Math.floor(Math.random() * look2items.length)]);
      look3items?.length > 0 &&
        items.push(look3items[Math.floor(Math.random() * look3items.length)]);
      look4items?.length > 0 &&
        items.push(look4items[Math.floor(Math.random() * look4items.length)]);

      return items;
    };

    const completeTheLookItems =
      page.completeTheLookCollection.items.length > 0 ?
        page.completeTheLookCollection.items :
        getRandomCompleteTheLookItems();

    let product: any;
    let completeTheLookShopifyData = []
    while (product = completeTheLookItems.pop()) {
        const shopifyProduct = await getProductByHandle(product.slug);
        const result = 
          shopifyProduct 
          ? {
            ...product,
            shopifyProduct,
          }
          : [];
        completeTheLookShopifyData.push(result)
    }

    const completeTheLookCollection = completeTheLookShopifyData.flat();

    const pageWithShopifyData = {
      ...page,
      shopifyProduct: await getProductByHandle(page.slug),
      completeTheLookCollection,
    };

    return pageWithShopifyData;
  }

  console.log('error returning product');
};

export const getAllProducts = async () => {
  const res = await fetchContent(
    `
      {
        productCollection(limit: 1000) {
          items {
            sys {
              id
              publishedAt
            }
            entryTitle
            title
            slug
            productBadge
            color {
              name
              value
            }
          }
        }
      }
    `
  );

  if (res?.productCollection?.items) {
    const products = res.productCollection.items;

    return products;
  }
};

export const getProductsByEntryTitles = async (productTitles: string[]): Promise<Partial<ContentfulProduct>[]> => {
  const argument = JSON.stringify(productTitles);
  const res = await fetchContent(
    `
    {
      productCollection(where: {entryTitle_in: ${argument}}) {
        items {
          entryTitle
          title
          color {
            name
            value
          }
          slug
        }
      }
    }
  `
  );

  if (res?.productCollection?.items.length === 0) {
    return []
  }
  return res.productCollection.items;
};
